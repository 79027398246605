import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"


const KarkasnoeStroitelstvo = ({ data }) => {
  return (
    <Layout>
      <div>
        <div className="mt-8">
          <div className="bg-transparent">
            <h2
              className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
              style={{
                fontFamily: `Montserrat`,
                fontWeight: `bold`,
                color: `white`,
                padding: `1rem`,
              }}
            >
              КАРКАСНОЕ СТРОИТЕЛЬСТВО
            </h2>
          </div>
        </div>
        <div
          className="mt-12 grid grid-cols-1 md:grid-cols-3"
          style={{ fontFamily: `Montserrat` }}
        >
          {data.allContentfulKarkasnoestroitelstvo.nodes.map(house => (
            <div key={house.id} className="p-2">
              <div>
                <Img
                  fluid={house.image.fluid}
                  className="w-full"
                  alt={house.title}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <p>
                {" "}
                <h3
                  className="text-white mt-8 mb-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{ fontFamily: `Montserrat` }}
                >
                  {house.title}
                </h3>
                <p className="text-white text-sm md:text-sm lg:text-sm xl:text-sm">
                  {documentToReactComponents(house.description.json)}
                </p>
              </p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default KarkasnoeStroitelstvo

export const PageQuery = graphql`
  query Karkas {
    allContentfulKarkasnoestroitelstvo {
      nodes {
        id
        title
        description {
          json
        }
        image {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
